<template>
  <b-media vertical-align="center">
    <b-card-header class="p-0">
      <h5>Código: {{ data.appointment_id}} ( {{ data.start_date }} - {{ data.start_time }} )</h5>

      <b-badge
        :variant="data.status.name == 'Confirmado' ? 'success' : 'warning'"
        text-color="white"
      >
        {{ data.status.name }}
      </b-badge>
    </b-card-header>

    <hr >
    <b-row>
      <b-col sm="10" md="6">
        <template>
          <b-avatar
            class="float-left mr-1"
            style="position: relative"
            :text="avatarText(data.patient.name)"
            :src="data.patient.image_profile"
          />
        </template>

        <b>{{ data.patient.name }}</b>
        <p class="text-muted mb-0">
          {{ data.specialty.name }}
        </p>
      </b-col>
      <b-col sm="2" md="3">
        <b-badge
          class="ma-2"
          :variant="data.modality ? 'success' : 'danger'"
          text-color="white"
        >
          {{ data.modality == true ? 'Online' : 'Presencial' }}
        </b-badge>
      </b-col>
      <b-col sm="12" md="2" class="text-right">
        <b-button v-if="data.status.name === 'Em Andamento' && data.modality" class="btn-success" @click="handleConfirm(data.uuid)">RETORNAR A SALA</b-button>
        <b-button v-if="data.status.name === 'Em Andamento' && !data.modality" class="btn-success" @click="handleConfirmFinal(data.uuid)">FINALIZAR ATENDIMENTO</b-button>
        <b-button v-if="data.status.name === 'Confirmado'" class="btn-success" @click="handleConfirm(data.uuid)">
          {{ !data.modality ? 'ATENDER' : 'ACESSAR' }}
        </b-button>
      </b-col>
    </b-row>
    <modal
      :visible="isModalVisible"
      :message="message"
      @confirm="handleConfirm(data.uuid)"
      @cancel="handleCancel"
    />
  </b-media>

</template>

<script>
import { BAvatar, BMedia, BBadge } from 'bootstrap-vue'
import Modal from '@/components/app/Modal.vue'

export default {
  name: 'DsProfessionalGridCard',
  components: {
    BMedia,
    BAvatar,
    BBadge,
    Modal,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isModalVisible: false,
      message: '',
    }
  },
  methods: {
    avatarText(value) {
      if (!value) {
        return ''
      }
      const words = value.split(' ')
      const nameArray = [words[0]]
      if (words.length > 0) {
        nameArray.push(words[words.length - 1])
      }

      return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
    },
    showModal() {
      this.isModalVisible = true
      this.message = 'Você tem certeza que deseja iniciar a consulta?'
    },
    async handleConfirm(uuid) {

      this.isModalVisible = false
      const data = {}
      let response = {}
      data.status = 'em-andamento'
      response = await this.$http.post(`appointments/${uuid}/status`, data)

      if (response.error) {
        this.$notify.error(response.error_message)
        return
      }
      if (!!this.data.modality) {
        this.$router.push({ path: `/video-room/${uuid}` }).catch(() => {})
      } else {
        this.$emit('presencial', true)
      }
    },
    async handleConfirmFinal(uuid) {
      const data = {}
      let response = {}
      data.status = 'concluido'
      response = await this.$http.post(`appointments/${uuid}/status`, data)
      this.$emit('presencial', true)
    },
    handleCancel() {
      this.isModalVisible = false
    },
  },
}
</script>

<style scoped></style>
